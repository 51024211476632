import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { IReward, IService, IGiftCard, ISendGift, IDiscountCoupon, SnackbarColor } from "./InappgiftingModels";

const initialGiftObj: ISendGift = {
  recipientName: "",
  recipientEmail: "",
  recipientMobileNumber: "",
  coupon: "",
  discounts: ""
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  tabNumber: number;
  giftObj: ISendGift;
  services: IService[];
  coupons: IDiscountCoupon[];
  rewards: IReward[];
  isRewardsOpen: boolean;
  giftObjErrors: ISendGift;
  giftCards: IGiftCard[];
  isOpenSnackbar: boolean;
  snackbarMessage: string;
  snackbarType: SnackbarColor;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class InappgiftingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getServicesAPIId: string = "";
  getRewardsAPIId: string = "";
  getDiscountCouponsAPIId: string = "";
  sendGiftCardAPIId: string = "";
  getGiftCardsAPIId: string = "";
  tabList: string[] = ["ALL", "REWARDS", "GIFTING", "SERVICES", "COUPONS"];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      tabNumber: 0,
      giftObj: initialGiftObj,
      services: [],
      coupons: [],
      rewards: [],
      isRewardsOpen: false,
      giftObjErrors: initialGiftObj,
      giftCards: [],
      isOpenSnackbar: false,
      snackbarMessage: "",
      snackbarType: "success"
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getServicesAPIId) {
        this.receiveAllServicesAPIResponse(responseJson);
      }

      if (apiRequestCallId === this.getRewardsAPIId) {
        this.receiveRewardsAPIResponse(responseJson);
      }

      if (apiRequestCallId === this.getDiscountCouponsAPIId) {
        this.receiveDiscountCouponsAPIResponse(responseJson);
      }

      if (apiRequestCallId === this.getGiftCardsAPIId) {
        this.receiveGiftCardsAPIResponse(responseJson);
      }

      if (apiRequestCallId === this.sendGiftCardAPIId) {
        this.receiveSendGiftCardAPIResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    this.getAllServices();
    this.getUserRewards();
    this.getDiscountCoupons();
    this.getGiftCards();
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const recipientInputString = event.target.value;
    const giftObject = { ...this.state.giftObj };
    this.setState({
      ...this.state,
      giftObj: { ...giftObject, [name]: recipientInputString }
    });
  }

  handleTabChange = (newValue: number) => {
    this.setState({ ...this.state, tabNumber: newValue, isRewardsOpen: false, giftObj: initialGiftObj, giftObjErrors: initialGiftObj });
  }

  handleSelectCoupon = (e: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ ...this.state, giftObj: { ...this.state.giftObj, coupon: e.target.value as string } })
  }

  onSelectCoupon = (couponId: string) => {
    this.setState({ ...this.state, tabNumber: 2, giftObj: { ...this.state.giftObj, coupon: couponId } });
  }

  setTabActiveColor = (tab: number) => {
    return tab === this.state.tabNumber ? "secondary" : "default";
  }

  validateGiftObject = () => {
    const giftData = { ...this.state.giftObj };
    const giftErrors = { ...initialGiftObj };
    const regexForEmail = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/);
    const regexForPhoneNumber = new RegExp(/^\d{10}$/);

    if (!giftData.recipientName.trim()) {
      giftErrors.recipientName = "Recipient Name is required";
    } else if (!giftData.recipientEmail.trim()) {
      giftErrors.recipientEmail = "Recipient Email is required";
    } else if (giftData.recipientEmail && !regexForEmail.test(giftData.recipientEmail)) {
      giftErrors.recipientEmail = "Please Enter Valid Email";
    } else if (!giftData.recipientMobileNumber.trim()) {
      giftErrors.recipientMobileNumber = "Recipient Mobile Number is required";
    } else if (giftData.recipientMobileNumber && !regexForPhoneNumber.test(giftData.recipientMobileNumber)) {
      giftErrors.recipientMobileNumber = "Please Enter Valid Mobile Number";
    } else if (!giftData.coupon.trim()) {
      giftErrors.coupon = "Discount Coupon is required";
    }
    if (JSON.stringify(giftErrors) !== JSON.stringify(initialGiftObj)) {
      this.setState({ ...this.state, giftObjErrors: giftErrors });
    } else {
      this.setState({ ...this.state, giftObjErrors: initialGiftObj });
      this.setGiftCardData();
    }
  }

  setDiscountCouponValue = () => {
    const filledGiftCardObj = { ...this.state.giftObj };
    const couponOb = this.state.coupons.find((coupon: IDiscountCoupon) => coupon.id === filledGiftCardObj.coupon);
    if (couponOb) {
      filledGiftCardObj.discounts = couponOb.attributes.discount_value;
    }
    return filledGiftCardObj;
  }

  setGiftCardData = () => {
    const gftCardData = this.setDiscountCouponValue();
    const formData = new FormData();
    formData.append("gift_card[discount_coupon_id]", gftCardData.coupon);
    formData.append("gift_card[receiver_name]", gftCardData.recipientName);
    formData.append("gift_card[receiver_email]", gftCardData.recipientEmail);
    formData.append("gift_card[receiver_phone]", gftCardData.recipientMobileNumber);
    formData.append("gift_card[gift_card_value]", gftCardData.discounts);

    this.sendGiftCard(formData);
  }

  getAllServices = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getServicesAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getServicesAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getUserRewards = () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRewardsAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRewardsAPIEndPoint + "?id=24"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getDiscountCoupons = () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDiscountCouponsAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDiscountCouponsAPIEndPoint + "?id=24"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getGiftCards = () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGiftCardsAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGiftCardAPIEndPoint + "?id=24"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  sendGiftCard = (giftCardData: FormData) => {
    const headers = {
      "Content-Type": undefined,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendGiftCardAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendGiftCardAPIEndPoint + "?id=24"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      giftCardData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  receiveAllServicesAPIResponse = (responseJson: { data: IService[] }) => {
    if (responseJson && responseJson.data) {
      this.setState({ ...this.state, services: responseJson.data });
    }
  }

  receiveRewardsAPIResponse = (responseJson: { data: IReward[] }) => {
    if (responseJson && responseJson.data) {
      this.setState({ ...this.state, rewards: responseJson.data });
    }
  }

  receiveDiscountCouponsAPIResponse = (responseJson: { data: IDiscountCoupon[] }) => {
    if (responseJson && responseJson.data) {
      this.setState({ ...this.state, coupons: responseJson.data });
    }
  }

  receiveGiftCardsAPIResponse = (responseJson: { data: IGiftCard[] }) => {
    if (responseJson && responseJson.data) {
      this.setState({ ...this.state, giftCards: responseJson.data });
    }
  }

  receiveSendGiftCardAPIResponse = (responseJson: { data?: IGiftCard, error?: string }) => {
    let snackbarMsg = "";
    let snackbarType: SnackbarColor = "success";
    if (responseJson && responseJson.data) {
      this.getDiscountCoupons();
      snackbarMsg = "Gift card sent successfully!";
    } else if (responseJson && responseJson.error) {
      snackbarMsg = responseJson.error;
      snackbarType = "error";
    }
    this.setState({ ...this.state, giftObj: initialGiftObj, giftObjErrors: initialGiftObj, tabNumber: 0, isOpenSnackbar: true, snackbarMessage: snackbarMsg, snackbarType: snackbarType });
    setTimeout(() => {
      this.setState({ ...this.state, isOpenSnackbar: false, snackbarMessage: "", snackbarType: "success" })
    }, 3000);
  }
  // Customizable Area End
}

import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  AppBar,
  Grid,
  Card,
  Chip,
  TextField,
  MenuItem,
  Select,
  Snackbar
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Alert from '@material-ui/lab/Alert';
import ShareIcon from '@material-ui/icons/Share';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { userImage, giftImage, saleImage1, saleImage2, saleImage3 } from "../src/assets";
import { IReward, IService, IGiftCard, IDiscountCoupon } from "./InappgiftingModels";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1876d2",
      contrastText: "#fff",
    },
    secondary: {
      main: "#dc004e",
      contrastText: "#fff",
    }
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import InappgiftingController, {
  Props,
  configJSON,
} from "./InappgiftingController";

export default class Inappgifting extends InappgiftingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {this.state.isOpenSnackbar && <Snackbar open={this.state.isOpenSnackbar} autoHideDuration={3000} anchorOrigin={{ horizontal: "right", vertical: "top" }}>
          <Alert severity={this.state.snackbarType}>
            {this.state.snackbarMessage}
          </Alert>
        </Snackbar>}
        <Container maxWidth={"md"}>
          <AppBar position="static" color="primary">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              {this.tabList.map((tab: string, index: number) => (
                <Button data-test-id={tab + "-btn"} variant="outlined" key={tab + "main-tab"} color={this.setTabActiveColor(index)} fullWidth onClick={() => this.handleTabChange(index)}>{tab}</Button>
              ))}
            </div>
          </AppBar>
          <div role="tabpanel" hidden={this.state.tabNumber !== 0}>
            <div>
              <Carousel showThumbs={false}>
                <div>
                  <img src={saleImage1} alt="sale1" height={400} />
                </div>
                <div>
                  <img src={saleImage2} alt="sale2" height={400} />
                </div>
                <div>
                  <img src={saleImage3} alt="sale3" height={400} />
                </div>
              </Carousel>
            </div>
            <Card raised style={{ display: "flex", alignItems: "center", margin: "10px 0px", padding: 10 }}>
              <div>
                <img src={userImage} height={40} alt="user" />
              </div>
              <div style={{ paddingLeft: 10 }}>
                <div>John Smith</div>
                <div style={{ fontSize: 14, color: "grey" }}>johnsmith01</div>
              </div>
            </Card>
            <div>
              {!this.state.giftCards.length ? <Card raised style={{ padding: 20 }}>Gift Cards Not Found!</Card> :
                <Grid container spacing={2}>
                  {this.state.giftCards.map((card: IGiftCard) => (
                    <Grid data-test-id="gift-card" key={card.id + "gift-card"} item xs={12} sm={12} md={6}>
                      <Card raised>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: 10 }}>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ paddingRight: 10 }}>
                              <img src={giftImage} alt="gift" height={40} />
                            </div>
                            <div>
                              <div style={{ fontWeight: 600 }}>{card.attributes.title}</div>
                              <div style={{ fontSize: 14, color: "grey" }}>{card.attributes.gift_card_value}</div>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              }
            </div>
          </div>
          <div role="tabpanel" hidden={this.state.tabNumber !== 1} style={{ margin: "10px 0px" }} data-test-id="rewards-div">
            {!this.state.rewards.length ? <Card raised style={{ padding: 20 }}>Rewards Not Found!</Card> :
              <>
                {!this.state.isRewardsOpen ? <Grid container spacing={2}>
                  {this.state.rewards.map((reward: IReward) => (
                    <Grid data-test-id="reward-card" item xs={12} key={reward.id + "reward"}>
                      <Card raised onClick={() => this.setState({ ...this.state, isRewardsOpen: true })}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: 10, cursor: "pointer" }}>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ paddingRight: 10 }}>
                              <img src={giftImage} alt="gift" height={40} />
                            </div>
                            <div>
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <div>
                                  <div style={{ fontWeight: 600 }}>{reward.attributes.title}</div>
                                  <div style={{ fontSize: 14, paddingTop: 5, display: "flex" }}>
                                    <span>{reward.attributes.description}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <Chip color="secondary" label={reward.attributes.code} />
                          </div>
                        </div>
                      </Card>
                    </Grid>
                  ))}
                </Grid> :
                  <Grid container data-test-id="reward-detail-card">
                    <Grid item xs={12}>
                      <Card raised>
                        <h3 style={{ display: "flex", justifyContent: "center" }}>Rewards</h3>
                        <h5 style={{ display: "flex", justifyContent: "center" }}>Congratulations, you won 2000 reward points</h5>
                        <div style={{ display: "flex", justifyContent: "center", paddingBottom: 10 }}>
                          <Button color="primary"
                            variant="contained" onClick={() => this.setState({ ...this.state, isRewardsOpen: false })}>Redeem
                          </Button>
                        </div>
                      </Card>
                    </Grid>
                  </Grid>}
              </>}
          </div>
          <div role="tabpanel" hidden={this.state.tabNumber !== 2} style={{ paddingTop: 20 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <div style={{ paddingBottom: 5 }}>Recipient Name*</div>
                <TextField fullWidth name="recipientName" value={this.state.giftObj.recipientName} placeholder="Please Enter Name" onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleInputChange(e, "recipientName")} variant="outlined" />
                <div data-test-id="recipient-name-error" style={{ fontSize: 12, color: "red", height: 14 }}>{this.state.giftObjErrors.recipientName}</div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div style={{ paddingBottom: 5 }}>Recipient Email*</div>
                <TextField fullWidth name="recipientEmail" value={this.state.giftObj.recipientEmail} placeholder="Please Enter Email" onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleInputChange(e, "recipientEmail")} variant="outlined" />
                <div data-test-id="recipient-email-error" style={{ fontSize: 12, color: "red", height: 14 }}>{this.state.giftObjErrors.recipientEmail}</div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div style={{ paddingBottom: 5 }}>Recipient Mobile Number*</div>
                <TextField fullWidth name="recipientMobileNumber" value={this.state.giftObj.recipientMobileNumber} placeholder="Please Enter Mobile Number" onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleInputChange(e, "recipientMobileNumber")} variant="outlined" />
                <div data-test-id="recipient-mobile-error" style={{ fontSize: 12, color: "red", height: 14 }}>{this.state.giftObjErrors.recipientMobileNumber}</div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div style={{ paddingBottom: 5 }}>Discount Coupon*</div>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={this.state.giftObj.coupon}
                  onChange={this.handleSelectCoupon}
                  variant="outlined"
                  fullWidth
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    <em>Please Select Discount Coupon</em>
                  </MenuItem>
                  {this.state.coupons.map((coupon: IDiscountCoupon) => (
                    <MenuItem key={coupon.id + "coupon-list"} value={coupon.id}>
                      {coupon.attributes.title}
                    </MenuItem>
                  ))}
                </Select>
                <div data-test-id="discount-error" style={{ fontSize: 12, color: "red", height: 14 }}>{this.state.giftObjErrors.coupon}</div>
              </Grid>
            </Grid>
            <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
              <Button data-test-id="send-gift-btn" size="large" variant="contained" color="primary" onClick={this.validateGiftObject}>SEND</Button>
            </div>
          </div>
          <div role="tabpanel" hidden={this.state.tabNumber !== 3} style={{ margin: "10px 0px" }}>
            {!this.state.services.length ? <Card raised style={{ padding: 20 }}>Services Not Found!</Card> :
              <Grid container spacing={2}>
                {this.state.services.map((service: IService) => (
                  <Grid data-test-id="service-card" item xs={12} sm={12} md={6} key={service.id + "service"}>
                    <Card raised>
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: 10 }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div>
                            <div style={{ fontWeight: 600 }}>{service.attributes.name}</div>
                            <div style={{ fontSize: 14, paddingTop: 5, display: "flex" }}>
                              <span style={{ fontSize: 14, color: "grey", textDecoration: "line-through" }}>{service.attributes.mrp}</span>
                              <span style={{ fontSize: 14, padding: "0px 10px", color: "black", fontWeight: "bold" }}>{service.attributes.selling_price}</span>
                              <span style={{ fontSize: 14, color: "#dc004e", padding: "0px 10px", fontWeight: "bold" }}>RS. {service.attributes.discount} Off</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            }
          </div>
          <div role="tabpanel" hidden={this.state.tabNumber !== 4} style={{ margin: "10px 0px" }}>
            {!this.state.coupons.length ? <Card raised style={{ padding: 20 }}>Discount Coupons Not Found!</Card> :
              <Grid container spacing={2}>
                {this.state.coupons.map((coupon: IDiscountCoupon) => (
                  <Grid data-test-id="coupon-card" item xs={12} sm={12} md={6} key={coupon.id + "coupon"}>
                    <Card raised>
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: 10 }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <IconButton color="secondary" onClick={() => this.onSelectCoupon(coupon.id)}><ShareIcon /></IconButton>
                              <div style={{ fontWeight: 600, paddingLeft: 10 }}>
                                {coupon.attributes.title}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div style={{ fontWeight: 600, color: "#dc004e" }}>{coupon.attributes.code}</div>
                        </div>
                      </div>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            }
          </div>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End

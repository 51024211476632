Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "inappgifting";
exports.labelBodyText = "inappgifting Body";
exports.getServicesAPIEndPoint = "bx_block_admin/services";
exports.getRewardsAPIEndPoint = "bx_block_admin/rewards";
exports.getDiscountCouponsAPIEndPoint = "bx_block_admin/discount_coupons";
exports.sendGiftCardAPIEndPoint = "bx_block_admin/gift_cards";
exports.getGiftCardAPIEndPoint = "bx_block_admin/gift_cards/received_gift_cards";
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End